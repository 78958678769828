<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    v-if="!user_data.parent_id"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mariana Tek</span>
      </template>

      <account-setting-mariana
        v-if="options.mariana"
        :mariana-data="options.mariana"
      />
    </b-tab>

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title v-if="user_data.role == 'pro'">
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Teams</span>
      </template>

      <account-setting-teams
        :team-data="options.team"
        v-if="user_data.role == 'pro'"
      />
    </b-tab>

  </b-tabs>
  <b-card bg-variant="danger" text-variant="white" v-else>
    <b-card-text>Only admin account is allowed to change settings.</b-card-text>
  </b-card>
</template>

<script>
/* eslint-disable */
import { BTabs, BTab, BCard, BCardText } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingMariana from './AccountSettingMariana.vue'
import AccountSettingZoom from './AccountSettingZoom.vue'
import AccountSettingTeams from './AccountSettingTeams.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardText,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingMariana,
    AccountSettingZoom,
    AccountSettingTeams,
  },
  data() {
    return {
      options: {},
    }
  },
  computed: {
    user_data() {
      return this.$store.state.app.user_data
    },
  },
  beforeCreate() {
    var self = this;
    this.$store.commit('app/loadingState', true)
    this.$http.get('https://live.ipstudio.co/api/account-setting').then(res => {
        this.$store.commit('app/loadingState', false)
        this.options = res.data 
    })
  }
}
</script>
