<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group
            label-for="accessToken"
            label="Access Token"
          >
          <b-input-group class="input-group-merge">
              <b-form-input
                id="accessToken"
                v-model="localOptions.access_token"
                :type="tokenFieldTypeaccess"
              />
              <b-input-group-append is-text>
                  <feather-icon
                    :icon="tokenToggleIconAccess"
                    class="cursor-pointer"
                    @click="toggleTokenAccess"
                  />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label-for="refreshToken"
            label="Refresh Token"
          >
            <b-input-group class="input-group-merge">
                <b-form-input
                  id="refreshToken"
                  v-model="localOptions.refresh_token"
                  :type="tokenFieldTyperefresh"
                />
                <b-input-group-append is-text>
                    <feather-icon
                      :icon="tokenToggleIconRefresh"
                      class="cursor-pointer"
                      @click="toggleTokenRefresh"
                    />
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="saveUserMeta"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    zoomData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.zoomData)),
      tokenFieldTypeaccess: 'password',
      tokenFieldTyperefresh: 'password',
    }
  },
  computed: {
    tokenToggleIconAccess() {
      return this.tokenFieldTypeaccess === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    tokenToggleIconRefresh() {
      return this.tokenFieldTyperefresh === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    toggleTokenAccess() {
      this.tokenFieldTypeaccess = this.tokenFieldTypeaccess === 'password' ? 'text' : 'password'
    },
    toggleTokenRefresh() {
      this.tokenFieldTyperefresh = this.tokenFieldTyperefresh === 'password' ? 'text' : 'password'
    },
    saveUserMeta() {
      const config = { meta_key: 'zoom', meta_value: JSON.stringify(this.localOptions) }
      store.dispatch('app/saveUserMeta', config)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
