<template>
  <b-card>
    <div class="row team-grid-holder align-items-center">
      <div v-for="(team, index) in teamData"  class="user-team-card col-md-4" :key="team.id">
        <b-card :title="team.fullname" :sub-title="team.email">
            <a href="#" class="card-link" @click.prevent="editUser(team)">Edit</a>
            <b-link href="#" class="card-link" @click.prevent="deleteUser(team, index)">Delete</b-link>
          </b-card>
      </div>
      <div class="user-team-card col-md-4 add-new" @click.prevent="addUser">
        <div class="card">
        Add New
        </div>
      </div>
    </div>
    <!-- form -->

    <div class="edit-user" v-if="showEditUser">
      <h3>Edit {{selectedUser.fullname}}</h3>
      <b-form class="edit-user-form mt-2">
        <b-form-group
          label="Name"
          label-for="edit-username"
        >
          <b-form-input
            id="edit-username"
            v-model="selectedUser.fullname"
          />
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="edit-email"
        >
          <b-form-input
            id="edit-email"
            v-model="selectedUser.email"
          />
        </b-form-group>
        <b-form-group
            label="Assign User Location"
        >
          <b-form-select v-model="selectedUser.location" :options="options"></b-form-select>
        </b-form-group>
        <b-button
          variant="primary"
          block
          type="submit"
          @click.prevent="updateUser"
        >
          Update User
        </b-button>
      </b-form>
    </div>

    <div class="add-new-user" v-if="showAddUser">
      <h3>Add New User</h3>
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form mt-2">
          <!-- username -->
          <b-form-group
            label="Name"
            label-for="register-username"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="register-username"
                v-model="username"
                name="register-username"
                :state="errors.length > 0 ? false:null"
                placeholder="John Doe"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- email -->
          <b-form-group
            label="Email"
            label-for="register-email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                id="register-email"
                v-model="userEmail"
                name="register-email"
                :state="errors.length > 0 ? false:null"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- password -->
          <b-form-group
            label-for="register-password"
            label="Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="register-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="register-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
                label="Assign User Location"
          >
            <b-form-select v-model="location" :options="options"></b-form-select>
          </b-form-group>

          <b-button
            variant="primary"
            block
            type="submit"
            @click.prevent="validationForm"
          >
            Create User
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@/views/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BCardText, BInputGroup, BInputGroupAppend, BImg, BCard, BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BCard,
    BCardText,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    teamData: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      location: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation
      required,
      email,
      options: [
        { value: null, text: 'Choose Location' },
      ],
      selectedUser: {},
      showAddUser: false,
      showEditUser: false,
    }
  },
  async mounted() {
    const self = this
    await this.$store.dispatch('app/getSubdomain')
    this.subdomain = this.$store.state.app.subdomain
    this.$store.commit('app/loadingState', true)
    this.$http.get('https://'+this.subdomain+'.marianatek.com/api/locations?page_size=100').then(res => {
      self.$store.commit('app/loadingState', false)
      res.data.data.forEach(function (value) {
        const tempOption = { value: value.id, text: value.attributes.name }
        self.options.push(tempOption)
      })
    })
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      let config
      const self = this
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          config = {
            name: this.username,
            email: this.userEmail,
            password: this.password,
            location: this.location,
            isChild: true
          }
          this.$http.post('https://live.ipstudio.co/register', config)
          .then(function (response) {
            console.log(response);
            self.teamData.push({fullname: self.username, email: self.userEmail, location: self.location})
            if(response.data.status == "success"){
              self.username = ''
              self.userEmail = ''
              self.password = ''
              self.location = null
            }
            self.showAddUser = false
            self.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.msg,
                icon: 'EditIcon',
                variant: response.data.status,
              }
            })
          })
          .catch(function (error) {
              console.log(error);
              self.$store.commit('app/authstate', false);
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong.',
                  icon: 'EditIcon',
                  variant: 'danger',
                }
              })
          });
        }
      })
    },
    addUser(team) {
      this.showAddUser = true
      this.showEditUser = false
    },
    editUser(team) {
      this.showAddUser = false
      this.showEditUser = true
      this.selectedUser = team
    },
    deleteUser(team, index) {
      this.showAddUser = false
      this.showEditUser = false
      this.selectedUser = team
      var self = this
      this.$http.post('https://live.ipstudio.co/api/delete-user', { data: this.selectedUser})
      .then(function (response) {
        self.teamData.splice(index, 1)
        self.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.msg,
            icon: 'EditIcon',
            variant: response.data.status,
          }
        })
      })
    },
    updateUser(){
      var self = this
      this.$http.post('https://live.ipstudio.co/api/update-user', { data: this.selectedUser})
      .then(function (response) {
        self.showEditUser = false
        self.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.msg,
            icon: 'EditIcon',
            variant: response.data.status,
          }
        })
      })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.team-grid-holder .card {
    border: 1px solid #eee;
    margin-bottom: 30px;
}
.team-grid-holder h6.card-subtitle, .team-grid-holder h4.card-title{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.user-team-card.add-new .card {
    text-align: center;
    background: #99243f;
    border: none;
    min-height: 132px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff !important;
}
</style>
